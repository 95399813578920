import styled from 'styled-components'
import { Card, Upload, Checkbox } from 'antd'
import { StlViewer } from 'react-stl-viewer'

export const Divider = styled.div`
  background-color: rgba(239, 242, 247, 1);
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const LabelText = styled.p`
  color: #495057;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
  width: 225px;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #495057;
  margin-bottom: 25px};
`

export const EnviromentName = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  color: #495057;
`

export const ExpandableTableCard = styled(Card)`
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  cursor: default;
  background: none;
  border: none;

  .ant-card-body {
    padding: 0px 15px 0px;
  }
  .detailsprice {
    width: 82px;
  }
  .ant-steps-vertical {
    margin-top: -6px;
  }
  .ant-steps-item-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #495057;
  }
  .formcolumn {
    padding: 0 5px 0 0;
    //max-width: 243px;
  }
  .ant-input-number-group > .ant-input-number:last-child,
  .ant-input-number-group-addon:last-child {
    border: none;
  }
  .dwidth {
    width: 45px;
    margin-right: 2px;
  }
  .ant-input-group {
    display: flex;
    justify-content: space-between;
    margin-left: -13px;
    width: 112%;
  }
  .white-bg {
    background-color: #fff !important;
  }

  .ant-form-item-control {
    align-items: flex-end;
  }
  .partsdivider {
    height: unset;
    margin: 0 40px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
  .ant-slider-horizontal {
    width: 77% !important;
    float: right;
  }
  .longlabelcheckbox .ant-form-item-label {
    width: 85%;
    text-align: left;
  }
  .ant-upload-list-item-thumbnail {
    object-fit: cover;
  }
  .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }

  #font-picker {
    width: 100%;
    height: 100%;
    box-shadow: none;

    .dropdown-button {
      background: #fff;
    }
    .dropdown-icon.finished::before {
      width: 10px;
      height: 10px;
      opacity: 0.25;
      border-top: none;
      border-left: none;
      border-right: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='64 64 896 896'%3E%3Cpath d='M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z'/%3E%3C/svg%3E");
    }

    ul {
      background-color: #ffffff;
      border-radius: 8px;
      outline: none;
      box-shadow:
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);

      li button:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
`

export const StyledUpload = styled(Upload)`
  height: 200px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: white;
  //padding: 0px 18px 35px 17px;
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='200' ry='200' stroke='grey' stroke-width='15' stroke-dasharray='2%2c 6' stroke-dashoffset='28' stroke-linecap='butt'/%3e%3c/svg%3e");
  // border-radius: 200px;
  .ant-upload-list,
  .ant-upload-select {
    width: 95% !important;
    height: 95% !important;
    margin-left: 3px;
    margin-bottom: 3px;
  }
  .ant-upload-list-item-container {
    width: 95% !important;
    height: 95% !important;
    margin-left: 3px !important;
    margin-top: -9px !important;
  }
`
export const StyledStlViewer = styled(StlViewer)`
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  :hover .overlay {
    opacity: 1;
  }

  .overlay div {
    color: white;
    text-decoration: none;
    margin: 10px;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .overlay div:first-child {
    position: absolute;
    bottom: 0;
    :hover {
      color: red;
    }
  }

  .overlay div:last-child {
    position: static;
    margin-top: 20px;
  }
`

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  flex-wrap: wrap;
  margin: 3px 0;
  .ant-checkbox-wrapper {
    margin-inline-start: 0;
    width: 125px;
    margin: 2px 0;
  }
`
